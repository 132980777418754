import useThemeMode from "../../../hooks/useTheme";
import { ArrowIcon } from "../../../constants/icons";
import { Box } from "@mui/material";
import { useEffect } from "react";

declare global {
    interface Window {
        googletag: any;
    }
}
// sx={{ minWidth: "320px", minHeight: "50px" }}
const Adwert = () => {
    const { theme }: any = useThemeMode();

    useEffect(() => {
        window?.googletag?.cmd?.push(function () {
            window.googletag.display("div-gpt-ad-1726843534995-0");
        });
    }, []);

    return (
        <Box id="adwert" sx={{ background: theme.palette.adwert.background }}>
            <Box id="div-gpt-ad-1726843534995-0" />

            <Box>
                <ArrowIcon width={20} height={12} />
            </Box>
        </Box>
    );
};

export default Adwert;
