import { Box } from "@mui/material";
import { FC, Fragment, useMemo, useState } from "react";
import { WordRow } from "../../../components";
import { GUESS_LENGTH, LETTER_LENGTH } from "../../../constants/settings";
import { WordleStateLocale, wordleStore } from "../../../features/wordle/WordleStore";
import { GameState } from "../../../utils/enums";
import { useLocales } from "../../../hooks";
import { GuessRow } from "../../../interfaces";

type IProps = {
    guess: string;
    sizeHeight: number;
    sizeWidth: number;
};

const Wordle: FC<IProps> = (props) => {
    const wordleState: WordleStateLocale = wordleStore();
    const { currentLang } = useLocales();
    const { guess: guessProp, sizeHeight, sizeWidth } = props;
    const [blockGapSize] = useState<number>(5);

    const tempRows: GuessRow[] = useMemo(() => [...wordleState[currentLang.value].rows], [wordleState, currentLang]);

    const currentRow: number = useMemo(
        () => (tempRows.length < GUESS_LENGTH ? tempRows.push({ guess: guessProp } as GuessRow) - 1 : GUESS_LENGTH),
        [tempRows, guessProp]
    );

    const numberOfGuessessRemaining: number = useMemo(() => GUESS_LENGTH - tempRows.length, [tempRows]);

    const rows: GuessRow[] = useMemo(
        () => tempRows.concat(Array(numberOfGuessessRemaining).fill({ guess: "" } as GuessRow)),
        [tempRows, numberOfGuessessRemaining]
    );

    const blockSizeForWidth: number = useMemo(() => (sizeWidth - blockGapSize * (LETTER_LENGTH - 1)) / LETTER_LENGTH, [sizeWidth, blockGapSize]);
    const blockSizeForHeight: number = useMemo(() => (sizeHeight - blockGapSize * (GUESS_LENGTH - 1)) / GUESS_LENGTH, [sizeHeight, blockGapSize]);

    const minSize: number = useMemo(() => Math.min(blockSizeForHeight, blockSizeForWidth) + 12, [blockSizeForHeight, blockSizeForWidth]);

    return useMemo(
        () => (
            <Fragment>
                <Box
                    id="wordle"
                    sx={{
                        maxWidth: `${LETTER_LENGTH * minSize}px`,
                        maxHeight: `${GUESS_LENGTH * minSize}px`,
                    }}>
                    {rows.map((word: GuessRow, index: number) => {
                        const isCurrentRow: boolean =
                            wordleState[currentLang.value].gameState === GameState.WON ? currentRow - 1 === index : currentRow === index;

                        return <WordRow key={index} letters={word.guess} result={word.result} isCurrentRow={isCurrentRow} maxBoxSize={minSize} />;
                    })}
                </Box>
            </Fragment>
        ),
        [minSize, rows, wordleState, currentLang, currentRow]
    );
};

export default Wordle;
