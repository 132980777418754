import { GameSummary, PointTable } from "./common";
import { IValueLabel } from "../../../../../interfaces";
import { GameState, ShareSocialMedia, StatisticTabs } from "../../../../../utils/enums";
import { useLocales, useTheme } from "../../../../../hooks";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon, ShareIcon } from "../../../../../constants/icons";
import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Menu, MenuItem, Slide, Tab, Tabs, Typography } from "@mui/material";
import { Dispatch, FC, ReactElement, Ref, SetStateAction, SyntheticEvent, forwardRef, useCallback, useEffect, useRef, useState } from "react";
import StatisticAdwert from "./common/StatisticAdwert";
import { WordleStateLocale, wordleStore } from "../../../../../features/wordle/WordleStore";
import { AuthState, authStore } from "../../../../../features/authenticate/AuthStore";
import useAlert from "../../../../../hooks/useAlert";
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import { generateShareContent } from "../../../../../utils/methods";

type IProps = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const StatisticDialog: FC<IProps> = (props) => {
    const { isOpen, setIsOpen } = props;
    const { theme, blindMode }: any = useTheme();
    const { translate, currentLang } = useLocales();
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const wordleState: WordleStateLocale = wordleStore();
    const authState: AuthState = authStore();
    const { showAlert } = useAlert();
    const [shareAnchorEl, setShareAnchorEl] = useState<null | HTMLElement>(null);
    const isShareOpen: boolean = Boolean(shareAnchorEl);
    const dialogContentRef: any = useRef<any>(null);
    const adwertRef: any = useRef<any>(null);
    const dialogContentTabsRef: any = useRef<any>(null);
    const pointTableRef: any = useRef<any>(null);
    const [dialogHeightForPointTable, setDialogHeightForPointTable] = useState<number>(0);

    useEffect(() => {
        const handleResize = () => {
            let totalHeight: number = 0;

            if (dialogContentRef?.current) {
                const tmpRect: DOMRect = dialogContentRef.current.getBoundingClientRect();

                totalHeight += tmpRect.height;
            }

            if (dialogContentTabsRef?.current) {
                const tmpRect: DOMRect = dialogContentTabsRef.current.getBoundingClientRect();

                totalHeight -= tmpRect.height;
            }

            if (adwertRef?.current) {
                const tmpRect: DOMRect = adwertRef.current.getBoundingClientRect();

                totalHeight -= tmpRect.height;
            }

            setDialogHeightForPointTable(totalHeight - 50);
        };

        window.addEventListener("resize", handleResize);
        window.addEventListener("orientationchange", handleResize);

        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("orientationchange", handleResize);
        };
    }, [dialogContentRef?.current, adwertRef?.current, dialogContentTabsRef?.current, pointTableRef?.current, selectedTab]);

    const handleClose = useCallback(() => {
        setShareAnchorEl(null);
    }, []);

    const closeHandler = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const tabChangeHandler = useCallback((_event: SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    }, []);

    const shareHandler = useCallback(
        (event: any) => {
            if (wordleState[currentLang.value].gameState === GameState.PLAYING) {
                setIsOpen(false);
                return showAlert(translate("share_not_game_ended"), 99999);
            }

            setShareAnchorEl(event.currentTarget);
        },
        [wordleState, currentLang]
    );

    useEffect(() => {
        let timeOut: NodeJS.Timeout;

        if (wordleState[currentLang.value].gameState !== GameState.PLAYING) {
            timeOut = setTimeout(() => {
                setIsOpen(true);
            }, 3000);
        }

        return () => {
            timeOut && clearTimeout(timeOut);
        };
    }, [wordleState[currentLang.value].gameState]);

    return (
        <Box id="statistic-dialog-box">
            <Dialog
                sx={{
                    "& .MuiDialog-paper": {
                        background: theme.palette.statisticDialog.background,
                    },
                    "& .MuiPaper-root": {
                        ...(selectedTab === StatisticTabs.PointTable && authState.isAuthenticate && { height: "85vh" }),
                    },
                }}
                open={isOpen}
                onClose={closeHandler}
                TransitionComponent={Transition}
                maxWidth="sm"
                fullWidth
                id="statistic-dialog">
                <DialogTitle>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography className="statistic-dialog-title">{translate("statistic")}</Typography>
                        <Box
                            sx={{ display: "flex", alignItems: "center", gap: 1, cursor: "pointer" }}
                            id="share-button"
                            aria-controls={isShareOpen ? "share-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={isShareOpen ? "true" : undefined}
                            onClick={(event: any) => shareHandler(event)}>
                            <Typography className="statistic-dialog-share">{translate("share")}</Typography>
                            <Box sx={{ display: "flex" }}>
                                <ShareIcon color={theme.palette.statisticDialog.icon} />
                            </Box>
                        </Box>
                        <Menu
                            id="share-menu"
                            anchorEl={shareAnchorEl}
                            open={isShareOpen}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: -70,
                            }}
                            disableAutoFocus
                            MenuListProps={{
                                "aria-labelledby": "share-button-list",
                                autoFocusItem: false,
                            }}>
                            <MenuItem disableGutters>
                                <FacebookShareButton
                                    className="share-button"
                                    style={{ padding: `${theme.spacing(1.5)} ${theme.spacing(2)}` }}
                                    url="https://www.bundle.app/gundem"
                                    title="title"
                                    content="content"
                                    hashtag={generateShareContent(wordleState[currentLang.value].rows, blindMode, ShareSocialMedia.FACEBOOK)}>
                                    <Typography className="share-button-text">Facebook</Typography>
                                    <FacebookIcon size={20} />
                                </FacebookShareButton>
                            </MenuItem>
                            <Divider />

                            <MenuItem disableGutters>
                                <TwitterShareButton
                                    className="share-button"
                                    style={{ padding: `${theme.spacing(1.5)} ${theme.spacing(2)}` }}
                                    url="https://www.bundle.app/gundem"
                                    title={generateShareContent(wordleState[currentLang.value].rows, blindMode, ShareSocialMedia.TWITTER)}>
                                    <Typography className="share-button-text">X</Typography>
                                    <TwitterIcon size={20} />
                                </TwitterShareButton>
                            </MenuItem>
                            <Divider />

                            <MenuItem disableGutters>
                                <LinkedinShareButton
                                    className="share-button"
                                    style={{ padding: `${theme.spacing(1.5)} ${theme.spacing(2)}` }}
                                    url="https://www.bundle.app/gundem"
                                    title="title"
                                    summary="summary"
                                    source="BundleApp">
                                    <Typography className="share-button-text">Linked In</Typography>
                                    <LinkedinIcon size={20} />
                                </LinkedinShareButton>
                            </MenuItem>
                            <Divider />

                            <MenuItem disableGutters>
                                <WhatsappShareButton
                                    className="share-button"
                                    style={{ padding: `${theme.spacing(1.5)} ${theme.spacing(2)}` }}
                                    url="https://www.bundle.app/gundem"
                                    title={generateShareContent(wordleState[currentLang.value].rows, blindMode, ShareSocialMedia.WHATSAPP)}>
                                    <Typography className="share-button-text">WhatsApp</Typography>
                                    <WhatsappIcon size={20} />
                                </WhatsappShareButton>
                            </MenuItem>
                        </Menu>
                    </Box>

                    <Box className="statistic-dialog-close-box" sx={{ background: theme.palette.statisticDialog.background }}>
                        <IconButton aria-label="close" onClick={closeHandler}>
                            <CloseIcon color={theme.palette.statisticDialog.icon} />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent id="statistic-dialog-content" ref={dialogContentRef}>
                    <Tabs
                        sx={{ background: theme.palette.statisticDialog.tabTitleBackground }}
                        value={selectedTab}
                        onChange={tabChangeHandler}
                        variant="scrollable"
                        scrollButtons={false}
                        ref={dialogContentTabsRef}
                        id="statistic-tab">
                        {statisticTabs.map((tab: IValueLabel<StatisticTabs>, index: number) => (
                            <Tab
                                className="statistic-tab-item"
                                sx={{ "&.Mui-selected": { background: blindMode ? "#599FAC" : "#31ac37" } }}
                                key={index}
                                label={translate(tab.label)}
                            />
                        ))}
                    </Tabs>

                    <Box>
                        {selectedTab === StatisticTabs.GameSummary && <GameSummary />}
                        {selectedTab === StatisticTabs.PointTable && <PointTable pointRef={pointTableRef} spaceHeight={dialogHeightForPointTable} />}
                    </Box>

                    {!authState.isPremium && (
                        <Box ref={adwertRef}>
                            <StatisticAdwert />
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        </Box>
    );
};

const statisticTabs: IValueLabel<StatisticTabs>[] = [
    {
        label: "gameSummary",
        value: StatisticTabs.GameSummary,
    },
    {
        label: "pointTable",
        value: StatisticTabs.GameSummary,
    },
];

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default StatisticDialog;
