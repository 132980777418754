import { create } from "zustand";
import { persist } from "zustand/middleware";
import { computeGuess, updateUserScore } from "../../utils/methods";
import { GUESS_LENGTH } from "../../constants/settings";
import { GameState, Languge, LetterState } from "../../utils/enums";
import { startOfDay } from "date-fns";
import { GuessRow, IComputeGuessResponse } from "../../interfaces";
import { PERSIST_WORDLE_VERSION } from "../../constants/versions";
import { Dispatch, SetStateAction } from "react";

export type WordleStateLocale = {
    [Languge.tr]: WordleState;
    [Languge.en]: WordleState;
    clear: () => void;
    addGuess: (guess: string, language: Languge.tr | Languge.en, setGuess: Dispatch<SetStateAction<string>>) => Promise<AddGuessResponse>;
    setGameTime: (newTime: Date, language: Languge.tr | Languge.en) => void;
};

export type KeyboardLetterState = {
    [letter: string]: LetterState;
};

export type WordleState = {
    rows: GuessRow[];
    gameState: GameState;
    gameTime: Date;
    keyboardLetterState: KeyboardLetterState;
};

export type AddGuessResponse = {
    word: string;
    isGameEnded: boolean;
    guessLength: number;
};

const wordleInitial: any = {
    [Languge.tr]: {
        rows: [] as GuessRow[],
        gameState: GameState.PLAYING,
        gameTime: startOfDay(new Date()),
        keyboardLetterState: {} as KeyboardLetterState,
    } as WordleState,
    [Languge.en]: {
        rows: [] as GuessRow[],
        gameState: GameState.PLAYING,
        gameTime: startOfDay(new Date()),
        keyboardLetterState: {} as KeyboardLetterState,
    } as WordleState,
};

export const wordleStore = create<WordleStateLocale>()(
    persist(
        (set, get) => ({
            ...wordleInitial,
            addGuess: async (
                guess: string,
                language: Languge.tr | Languge.en,
                setGuess: Dispatch<SetStateAction<string>>
            ): Promise<AddGuessResponse> => {
                const result: IComputeGuessResponse = await computeGuess(guess, language);

                const tempGuess: string = guess;

                const rows: GuessRow[] = [
                    ...get()[language].rows,
                    {
                        guess: tempGuess,
                        result: result.match,
                    },
                ];

                const keyboardLetterState: KeyboardLetterState = get()[language].keyboardLetterState;

                result.match.forEach((result: LetterState, index: number) => {
                    const resultGuessLetter: string = tempGuess[index];

                    keyboardLetterState[resultGuessLetter] = result;
                });

                const isWin: boolean = result.match.every((value: LetterState) => value === LetterState.MATCH);

                const newGameState: GameState = isWin ? GameState.WON : rows.length === GUESS_LENGTH ? GameState.LOST : GameState.PLAYING;

                const isGameEnded: boolean = newGameState === GameState.LOST || newGameState === GameState.WON;

                if (isGameEnded) {
                    await updateUserScore(get()[language].gameTime, newGameState, language);
                }

                setGuess("");

                set(
                    () =>
                        ({
                            ...get(),
                            [language]: {
                                ...get()[language],
                                rows,
                                gameState: newGameState,
                                ...keyboardLetterState,
                            },
                        } as WordleStateLocale)
                );

                return { word: result.word, isGameEnded, guessLength: rows.length } as AddGuessResponse;
            },
            clear: () => {
                set(() => wordleInitial);
            },
            setGameTime: (newTime: Date, language: Languge.tr | Languge.en) => {
                set(() => ({
                    ...get(),
                    [language]: {
                        ...get()[language],
                        gameTime: new Date(newTime),
                    },
                }));
            },
        }),
        {
            name: "wordle",
            version: PERSIST_WORDLE_VERSION,
            migrate: (persistState: any, versionNumber: number) => {
                if (versionNumber !== PERSIST_WORDLE_VERSION) {
                    return { ...wordleInitial, ...persistState };
                }

                return persistState;
            },
        }
    )
);
